<template>
    <div class="auth-with" v-bind:class="{disabled: true}">
            <div class="small-logo">
                <span class="iconify" 
				style="color: gray;" 
				data-icon="logos:apple" 
				data-width="20" 
				data-height="20">
				</span>
            </div>
            <div class="auth-title">Continue with Apple</div>
        </div>
</template>

<script>
export default {
    name: "AppleSignIn",
}
</script>

<style scoped>
.auth-with{
    padding: 11px 17px;
    max-width: 270px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;

	width: 100%;
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.small-logo{
    height: 20px;
}
.auth-title{
    text-align: center;
}
.logo .iconify{
    height: 105px;
    width: 170px;
    color: grey;
}
.disabled{
    color :grey;
    background: #e6e6e6;
}

.disabled .logo .iconify{
    color: grey;
}
</style>