<template>
    <div class="auth-page">
        <!-- <Header style="position: inherit;" :isAuthPage="true"/> -->

		
		<!-- <div class="logo">
            <div class="logo-cont">
                <span class="iconify" data-icon="emojione:framed-picture"></span>
            </div>
            <div class="logo-title">
                Logo
            </div>
        </div> -->

		<div class="auth-page__content">
  
			<div class="home-page__title">
				<div class="title-part">HORA</div>
				<div class="title-part">GURU</div>
			</div>

			
			<GoogleSingIn @on-click="googleSignIn"/>
			<AppleSignIn/>
			<div id="issue">
				<div class="issue-cont">
					<a href="">Issues with log in?</a>
				</div>
			</div>
		</div>

		<UsefulLinks/>
    </div>
</template>

<script>
import Header from '../components/header/Header.vue'
import CMS from '../service/cms/service'
import router from '../router'
import AuthService from '../service/auth-service.js'
import AppleSignIn from '../components/signin-buttons/AppleSignIn.vue'
import GoogleSingIn from '../components/signin-buttons/GoogleSignIn.vue'
import UsefulLinks from '../components/UsefulLinks.vue'

export default {
    name: 'Auth',
    components: {
        Header,
        AppleSignIn,
		GoogleSingIn,
		UsefulLinks
    },
    mounted(){
        let code = this.$route.query.code
        if (code){
            AuthService.signIn({code, authMethod: 1})
                .then(response => {
                    if (response.data.err)
                        throw response.data.err
                    this.setupUserInfo({
                        firstName : response.data.firstName,
                        lastName : response.data.lastName,
                        email : response.data.email,
                    })
                })
        }
    },
    methods: {
        googleSignIn(){
            AuthService.getOauthData(1)
                .then(data => {
                    if (data.err || !data.authUri)
                        return
                    window.open(data.authUri, "_self")
                })
        },

        setupUserInfo(userData){
            CMS.user.getInfo()
                .then(({data, status}) => {
                    if (data.err)
                        throw data.err
                    
                    this.$store.dispatch("setup-geo")

                    let isNewUser = status == 204
                    if (isNewUser) {
                        this.newUserHandler(userData)
                        return
                    }
                    
                    let profile = data
                    this.saveUserProfile(profile)

                    let lastCalendarInLocalStorage = Boolean(localStorage.getItem("last-calendar"))
                    if (lastCalendarInLocalStorage) {
                        router.replace(localStorage.getItem("last-calendar"))
                        localStorage.removeItem("last-calendar")
                        return
                    }

					const defaultCalendar = profile.calendars.find(calendar => calendar.isDefault)
					const calendarUid = defaultCalendar.uid
                    router.replace({name: 'calendar-schedule', params: {
						calendarUid: calendarUid,
					}}) // --> to calendar page
                })
        },

        newUserHandler(userData){
            localStorage.setItem('hora-user-first-name', userData.firstName)
            localStorage.setItem('hora-user-last-name', userData.lastName)
            localStorage.setItem('hora-user-email', userData.email)
            this.$store.dispatch('update-user-data', {
                isLogin: true,
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email,
            })
            router.push('welcome') // --> to welcome page
        },
        
        visitedCalendarHandler(){
			if (calendar && calendar.isPublicForUser()){
				router.replace({
					name: 'calendar-schedule', 
					params: {
						calendarUid: calendar.uid
					}
				})
			}
        },
        
        saveUserProfile(profile){
            let profileDate = {
                calendars: profile.calendars,
                firstName: profile.firstName,
                // no image url
                lastName: profile.lastName,
                shortName: profile.shortName,
                email: profile.email,
                
                countryId: profile.countryId,
                timezoneId: profile.timezoneId,
                workdayCalendarId: profile.workdayCalendarId,
            }

            this.$store.dispatch('update-user-data', {
                isLogin: true,
                ...profileDate
            })
        }
    },
}
</script>

<style scoped>
.auth-page{
	height: 100%;
}
.logo{
    height: 150px;
    width: 250px;
    margin: 60px 0 40px 0;
    margin-right: auto;
    margin-left:auto;
    background: #e6e6e6;
    border-radius: 10px;
}
.auth-with{
    padding: 11px 17px;
    max-width: 270px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;

    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.title{
    text-align: center;
}
.logo .iconify{
    height: 105px;
    width: 170px;
    color: grey;
}
.logo-cont{
    padding: 10px 40px 0 40px;
}
.logo-title{
    text-align: center;
    height: fit-content;
}
.small-logo{
    height: 19.2px;
}
#issue{
    font-size: 14px;
    position: absolute;
    bottom: 25px;
    left: 0;
}
.issue-cont{
    text-align: center;
    width: 100vw;
}

.home-page__title{
	width: 100%;
	height: 96px;
	font-size: 48px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 36px;
}

.title-part{
	height: 40px;
	text-align: center;
}

.auth-page__content{
	display: flex;
	flex-direction: column;
    padding: 24px;
	gap: 16px;
	justify-content: center;
	align-items: center;
}
</style>