<template>
	<div class="auth-with" @click="onClick">
		<div class="small-logo">
			<span class="iconify" 
			data-icon="flat-color-icons:google" 
			style="color: #135345;" 
			data-width="20" 
			data-height="20">
			</span>
		</div>
		<div class="auth-title">Continue with Google</div>
	</div>
</template>

<script>
export default {
	name: 'GoogleSignIn',
	emits: [
		'on-click',
	],
	methods: {
		onClick(){
			this.$emit('on-click')
		}	
	},
};
</script>

<style scoped>
.auth-with{
    padding: 11px 17px;
    max-width: 270px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;

	width: 100%;
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.small-logo{
    height: 20px;
}
.auth-title{
    text-align: center;
}
.logo .iconify{
    height: 105px;
    width: 170px;
    color: grey;
}
.disabled{
    color :grey;
    background: #e6e6e6;
}

.disabled .logo .iconify{
    color: grey;
}
</style>